import {NOTE_SHARE, NOTES, MOVE_NOTE, NOTE_DUPLICATE, NOTE_RENAME, NOTE_UPLOAD_IMAGE} from "@/constants/api";
import axios from "axios";

export async function getNote(noteId: number | string, teamId: number | null = null) {
  const url = NOTES + '/' + noteId;
  return await axios.get(url, {
    params: {
      teamId: teamId
    }
  });
}

export async function newReq(engine: number,
                             parentId: number,
                             teamId: number | null = null) {
  const teamIdObj = teamId ? {teamId: teamId} : {}
  return await axios.post(NOTES, {
    ...teamIdObj,
    engine: engine,
    parentId: parentId
  });
}

export async function updateReq(id: number,
                                name: string,
                                content: string,
                                teamId: number | null = null) {
  const teamIdObj = teamId ? {teamId: teamId} : {}
  return await axios.patch(NOTES, {
    ...teamIdObj,
    id: id,
    name: name,
    content: content
  });
}

export async function renameReq(id: number,
                                name: string,
                                teamId: number | null = null) {
  const teamIdObj = teamId ? {teamId: teamId} : {}
  return await axios.patch(NOTE_RENAME, {
    ...teamIdObj,
    id: id,
    name: name
  });
}

export async function deleteReq(id: number, teamId: number | null = null) {
  return await axios.delete(`${NOTES}/${id}`, {
    params: {
      teamId: teamId
    }
  })
}

export async function shareNote(form: any) {
  return await axios.post(NOTE_SHARE, form);
}

export async function moveReq(id: number,
                              targetFolderId: number,
                              teamId: number | null = null) {
  const teamIdObj = teamId ? {teamId: teamId} : {}
  return await axios.patch(MOVE_NOTE, {
    ...teamIdObj,
    id: id,
    parentId: targetFolderId
  });
}

export async function duplicateReq(id: number,
                                   teamId: number | null = null) {
  const teamIdObj = teamId ? {teamId: teamId} : {}
  return await axios.post(NOTE_DUPLICATE, {
    ...teamIdObj,
    id: id
  });
}

export async function uploadImageReq(id: number,
                                     formData: FormData,
                                     teamId: number | null = null) {
  const url = NOTE_UPLOAD_IMAGE + '?noteId=' + id;
  return await axios.post(url, formData, {
    params: {
      teamId: teamId
    }
  });
}