
  import {computed, defineComponent, onMounted, reactive, toRefs} from 'vue';
  import {apiResponseErrorHandle} from "@/composables/errorHandler";
  import {ElMessage} from "element-plus/es";
  import useFolders from "@/composables/useFolders";
  import useNotes from '@/composables/useNotes';
  import {FILE_TYPE_FOLDER, FILE_TYPE_NOTE} from "@/constants/file-type";
  import { watch } from 'vue';

  export default defineComponent({
    name: 'MoveDialog',
    props: {
      dialogVisible: Boolean,
      onClose: Function,
      onMoved: Function,
      moveItem: Object,
      startFolderId: Number
    },
    setup(props) {
      const {getFoldersAndNotes, getRecursiveParents, moveFolder} = useFolders();
      const {moveNote} = useNotes();

      interface MoveDialogIf {
        dialogRecursiveParents: any[],
        dialogTableCurrentFolder: any,
        dialogTableData: any[]
      }
      const state: MoveDialogIf = reactive({
        dialogRecursiveParents: [],
        dialogTableCurrentFolder: {
          id: null, //If get a large number ID, must change ID as String.
        },
        dialogTableData: []
      });

      watch(() => props.dialogVisible, async (newValue) => {
        if (newValue === true) {
          initDataForDialog();
        }
      })

      const closeDialog = () => {
        props.onClose && props.onClose();
        state.dialogRecursiveParents = [];
      }

      const handleDialogClose = (done: () => void) => {
        closeDialog()
        done();
      }

      const initDataForDialog = () => {
        listByParentFolderId(props.startFolderId);
        state.dialogRecursiveParents = [];
        listRecursiveParents(props.startFolderId);
      }

      const dialogVisit = (row: any) => {
        listByParentFolderId(row.id);
        listRecursiveParents(row.id);
      }

      const listByParentFolderId = (parentFolderId: number | string | null = null) => {
        getFoldersAndNotes(parentFolderId).then(function (response) {
          state.dialogTableCurrentFolder = response.data.currentFolder;
          state.dialogTableData = response.data.folders;
          state.dialogTableData = state.dialogTableData.concat(response.data.notes);
        }).catch(apiResponseErrorHandle('获取数据失败'));
      };

      const listRecursiveParents = (currentFolderId: number | string | null = null) => {
        getRecursiveParents(currentFolderId).then(function(response) {
          state.dialogRecursiveParents = response.data.parents;
        }).catch(apiResponseErrorHandle('获取面包屑数据失败'));
      };

      const dialogMoveTo = () => {
        const targetFolderId = state.dialogTableCurrentFolder?.id;
        if (!targetFolderId) {
          ElMessage({
            showClose: true,
            message: "获取目标文件夹错误",
            type: 'error',
          });
          return
        }
        const id = props.moveItem?.id;
        if (props.moveItem?.fileType == FILE_TYPE_FOLDER) {
          moveFolder(id, targetFolderId).then(function(response) {
            ElMessage({
              showClose: true,
              message: '移动成功',
              type: 'success',
            });
            props.onMoved && props.onMoved()
          }).catch(apiResponseErrorHandle('移动失败'))
        } else if (props.moveItem?.fileType == FILE_TYPE_NOTE) {
          moveNote(id, targetFolderId).then(function(response) {
            ElMessage({
              showClose: true,
              message: '移动成功',
              type: 'success',
            });
            props.onMoved && props.onMoved()
          }).catch(apiResponseErrorHandle('移动失败'))
        }
      }

      return {
        ...toRefs(state),
        closeDialog,
        handleDialogClose,
        listRecursiveParents,
        dialogVisit,
        dialogMoveTo,
        visible: computed(() => props.dialogVisible),
        moveButtonDisabled: computed(() => props.startFolderId === state.dialogTableCurrentFolder.id
          || (props.moveItem?.id === state.dialogTableCurrentFolder.id
            && props.moveItem?.fileType === FILE_TYPE_FOLDER)
          || (state.dialogRecursiveParents.some(v => v?.id === props.moveItem?.id)
            && props.moveItem?.fileType === FILE_TYPE_FOLDER))
      }
    }
  });
