import {getNote as getOneNote, shareNote as shareOneNote, moveReq, newReq, deleteReq, duplicateReq, renameReq, uploadImageReq, updateReq} from '@/api/notes';
import {getSharedNote as getOneSharedNote} from '@/api/sharedNotes';
import { MARKDOWN_ENGINE_VDITOR } from '@/constants/note';
import {useStore} from "vuex";

export default function useNotes(storeArg: any = null) {
  const getStore = () => {
    if (!storeArg) {
      return useStore();
    } else {
      return storeArg
    }
  }

  const store = getStore();

  const getNote = async (noteId: number | string) => {
    const teamId = store.state.currentTeam?.id
    return await getOneNote(noteId, teamId);
  }

  const createNote = async (engine: number, parentId: number) => {
    const teamId = store.state.currentTeam?.id
    return await newReq(engine, parentId, teamId);
  }

  const updateNote = async (id: number, name: string, content: string) => {
    const teamId = store.state.currentTeam?.id
    return await updateReq(id, name, content, teamId);
  }

  const renameNote = async (id: number, name: string,) => {
    const teamId = store.state.currentTeam?.id
    return await renameReq(id, name, teamId);
  }

  const deleteNote = async (id: number) => {
    const teamId = store.state.currentTeam?.id
    return await deleteReq(id, teamId);
  }

  const shareNote = async (form: any) => {
    return await shareOneNote(form);
  }

  const getShareUrl = (data: any, engineType: number | undefined) => {
    let url = data.host + '/shared/notes/';
    if (engineType == MARKDOWN_ENGINE_VDITOR) {
      url = url + 'v/'
    }
    return url + data.noteId + `?sid=${data.sid}&token=${data.token}`
  }

  const getSharedNote = async (noteId: number | string, params?: any) => {
    return await getOneSharedNote(noteId, params);
  }

  const moveNote = async (id: number, targetFolderId: number) => {
    const teamId = store.state.currentTeam?.id
    return await moveReq(id, targetFolderId, teamId);
  }

  const duplicateNote = async (id: number) => {
    const teamId = store.state.currentTeam?.id
    return await duplicateReq(id, teamId);
  }

  const uploadImage = async (id: number, formData: FormData) => {
    const teamId = store.state.currentTeam?.id
    return await uploadImageReq(id, formData, teamId);
  }

  return {
    getNote,
    shareNote,
    getSharedNote,
    getShareUrl,
    moveNote,
    createNote,
    renameNote,
    deleteNote,
    duplicateNote,
    uploadImage,
    updateNote
  }

}