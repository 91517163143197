import {FOLDERS, LIST_RECURSIVE_PARENTS, MOVE_FOLDER, NOTES} from "@/constants/api";
import axios from "axios";

export async function fetchFoldersAndNotes(parentFolderId: number | string | null = null,
                                           teamId: number | null = null) {
  return await axios.get(FOLDERS, {
    params: {
      parentFolderId: parentFolderId,
      teamId: teamId
    }
  });
}

export async function fetchRecursiveParents(currentFolderId: number | string | null = null,
                                            teamId: number | null = null) {
  return await axios.get(LIST_RECURSIVE_PARENTS, {
    params: {
      currentFolderId: currentFolderId,
      teamId: teamId
    }
  });
}

export async function createFolderReq(parentFolderId: number | string | null = null,
                                      name: string,
                                      teamId: number | null = null) {
  const teamIdObj = teamId ? {teamId: teamId} : {}
  return await axios.post(FOLDERS, {
    ...teamIdObj,
    parentId: parentFolderId,
    name: name
  });
}

export async function updateFolderReq(id: number,
                                      name: string,
                                      teamId: number | null = null) {
  const teamIdObj = teamId ? {teamId: teamId} : {}
  return await axios.patch(FOLDERS, {
    ...teamIdObj,
    id: id,
    name: name
  });
}

export async function moveReq(id: number | string | null = null,
                              targetFolderId: number,
                              teamId: number | null = null) {
  const teamIdObj = teamId ? {teamId: teamId} : {}
  return await axios.patch(MOVE_FOLDER, {
    ...teamIdObj,
    id: id,
    parentId: targetFolderId
  });
}

export async function deleteReq(id: number, teamId: number | null = null) {
  return await axios.delete(`${FOLDERS}/${id}`, {
    params: {
      teamId: teamId
    }
  })
}