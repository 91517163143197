import {createFolderReq, deleteReq, fetchFoldersAndNotes, fetchRecursiveParents, moveReq, updateFolderReq} from '@/api/folders';
import { useStore } from 'vuex'

export default function useFolders() {
  const store = useStore();

  const getFoldersAndNotes = async (parentFolderId: number | string | null = null) => {
    const teamId = store.state.currentTeam?.id
    return await fetchFoldersAndNotes(parentFolderId, teamId);
  }

  const getRecursiveParents = async (parentFolderId: number | string | null = null) => {
    const teamId = store.state.currentTeam?.id
    return await fetchRecursiveParents(parentFolderId, teamId);
  }

  const createFolder = async (parentFolderId: number | string | null = null, name: string) => {
    const teamId = store.state.currentTeam?.id
    return await createFolderReq(parentFolderId, name, teamId);
  }

  const updateFolder = async(id: number, name: string) => {
    const teamId = store.state.currentTeam?.id
    return await updateFolderReq(id, name, teamId);
  }

  const moveFolder = async (id: number, targetFolderId: number) => {
    const teamId = store.state.currentTeam?.id
    return await moveReq(id, targetFolderId, teamId);
  }

  const deleteFolder = async (id: number) => {
    const teamId = store.state.currentTeam?.id
    return await deleteReq(id, teamId);
  }

  return {
    getFoldersAndNotes,
    getRecursiveParents,
    createFolder,
    moveFolder,
    deleteFolder,
    updateFolder
  }

}