import {ElMessage} from "element-plus";

export function apiResponseErrorHandle(defaultErrorText: string, cb: (() => void) | null = null) {
  return function (error: any) {
    if (cb) {
      cb();
    }
    let message;
    if (!error || !error.response || !error.response.data) {
      message = defaultErrorText;
    } else {
      message = error.response.data.error;
    }
    ElMessage({
      showClose: true,
      message: message,
      type: 'error',
    });
  }
}