import {MARKDOWN_ENGINE_BUILD_IN, MARKDOWN_ENGINE_VDITOR} from "@/constants/note";
import {copyToClipboard} from "@/utils/command";

export default function useMarkdownLinkCopy() {
    const copyMarkdownLink = (row: any) => {
        let path = ''
        if (row.fileType == 1) {
            path = `/folders/${row.id}`
        } else {
            if (row.engine == MARKDOWN_ENGINE_VDITOR) {
                path = `/notes/v/show/${row.id}`
            } else if (row.engine == MARKDOWN_ENGINE_BUILD_IN) {
                path = `/notes/show/${row.id}`
            } else {
                path = `/notes/v/show/${row.id}`
            }
        }
        return copyMarkdownLinkByFullPath({fullPath: path, name: row.name});
    }

    const copyMarkdownLinkByFullPath = (params: any) => {
        const text = `[${(params.name)}](${params.fullPath} "${(params.name)}")`
        return copyToClipboard(text)
    }

    return {
        copyMarkdownLink,
        copyMarkdownLinkByFullPath
    }
}