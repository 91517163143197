export function copyToClipboard(text: string): boolean {
    const tempElement = document.createElement('input');
    document.body.appendChild(tempElement);
    tempElement.value = text;
    tempElement.focus();
    tempElement.select();
    const result = document.execCommand('copy');
    tempElement.blur();
    document.body.removeChild(tempElement);
    return result;
}